<template>

<div>

	<com-title text="Conventions" />

	<com-item route="Conventions.Browse" text="Browse" />

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./../../panel/common/Title'),
		'com-item': () => import('./../../panel/common/Item')
	}

}

</script>

<style scoped>

</style>
